<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('@/assets/tulogo.png')"
          class="my-3"
          contain
          height="100"
          >
        </v-img>
      </v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Empty
        </h1>
        <p class="subheading font-weight-regular">
          Gracias por tu visita,
          <br>click en el boton
        </p>
        <v-btn
          to="/"
          class="ma-2"
          outlined
          color="indigo"
          >
            inicio
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'EmptyForm',
    props: {
      //
    },
    components: {
      //
    },
    data: () => ({
      //
    }),
    methods: {
      initialize: function () {
        //
      },
      processData: function () {
        //
      }
    },
    created () {
      //
    },
    mounted () {
      //
    },
    updated () {
      //
    },
    watch: {
      //
    },
    computed: {
      //
    }
  }
</script>
